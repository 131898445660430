module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"universal-patterns-landing","short_name":"universal-patterns","start_url":"/","background_color":"#15171B","theme_color":"#87D7DE","display":"minimal-ui","icon":"src/images/up-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a0e092fd6103b22b01d37e24aa55806f"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"universal-patterns","accessToken":"MC5ZRmVXUWhNQUFDQUFJVWpp.HRlq77-977-9WO-_ve-_vX8T77-9EVXvv70JLQ3vv73vv70777-977-977-977-9TX4Z77-9I1Tvv73vv70","schemas":{"homepage":{"Main":{"page_title":{"type":"Text","config":{"label":"Page title"}},"uid":{"type":"UID","config":{"label":"uid"}}},"Overview":{"overview_title":{"type":"StructuredText","config":{"single":"strong","label":"Title"}},"overview_body":{"type":"StructuredText","config":{"multi":"paragraph, heading3, strong, em","label":"Body"}}},"Portfolio":{"portfolio_title":{"type":"Text","config":{"label":"Title"}},"projects":{"type":"Group","config":{"fields":{"video_poster":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Video poster"}},"name":{"type":"Text","config":{"label":"Name"}},"video":{"type":"Link","config":{"select":"media","label":"Video"}},"video_source":{"type":"Text","config":{"label":"Video source"}},"external_link":{"type":"Link","config":{"label":"External link"}}},"label":"Projects"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-205076944-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    }]
